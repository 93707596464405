import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import withMemo from '../../decorators/withMemo'
import Button from '../Button'

import useStyles from './styles'


const ProgressNav = (props) => {
  const { className, currentStep, totalSteps, buttonPrevProps, buttonNextProps, textPrev, textNext, hideProgressOnMobile } = props

  const [percentage, setPercentage] = useState((currentStep + 1) / totalSteps * 100)

  const classes = useStyles({ ...props, percentage })

  useEffect(() => {
    setPercentage((currentStep + 1) / totalSteps * 100)
  }, [currentStep, totalSteps])

  return (
    <nav className={cx(className, classes.container, hideProgressOnMobile && classes.hideProgressOnMobile)}>
      <Button
        text={textPrev}
        {...buttonPrevProps}
      />
      <div className={classes.progress}>
        <progress
          className={classes.progressNative}
          max="100"
          value={percentage ?? 0}
        />
      </div>
      <Button
        text={textNext}
        {...buttonNextProps}
      />
    </nav>
  )
}

ProgressNav.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  buttonPrevProps: PropTypes.shape(Button.propTypes),
  buttonNextProps: PropTypes.shape(Button.propTypes),
  textPrev: PropTypes.string,
  textNext: PropTypes.string,
  hideProgressOnMobile: PropTypes.bool,
}

ProgressNav.defaultProps = {
  className: null,
  currentStep: 0,
  totalSteps: 0,
  buttonPrevProps: null,
  buttonNextProps: null,
  textPrev: '',
  textNext: '',
  hideProgressOnMobile: false,
}

export default withMemo()(ProgressNav)
