import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import lottie from 'lottie-web'

import withMemo from '../../decorators/withMemo'

import useStyles from './styles'


const LottieTimeline = forwardRef((props, ref) => {
  const classes = useStyles(props)
  const { className, animationPath, animationSegments } = props
  const $animation = useRef(null)
  const [animationState, setAnimationState] = useState({ index: 0, direction: 1 })
  const $animationContainer = useRef(null)

  const onWindowResize = useCallback(() => {
    if (!$animation.current) {
      return
    }

    $animation.current.resize()
  }, [$animation])

  useEffect(() => {
    if (
      !$animation.current
      || !$animationContainer.current
      || !animationPath
      || !animationSegments
    ) {
      $animation.current = lottie.loadAnimation({
        container: $animationContainer.current,
        path: animationPath,
        renderer: 'canvas',
        loop: false,
        autoplay: false,
        initialSegment: [0, 0],
        rendererSettings: {
          clearCanvas: true,
        },
      })
      $animation.current.setSubframe(true)
      $animation.current.addEventListener('data_ready', () => {
        $animation.current.playSegments([animationSegments[0][0], animationSegments[0][1]], true)
      })
    }
  }, [$animation, $animationContainer, animationPath, animationSegments])

  useEffect(() => {
    if (
      !$animation.current
      || !animationSegments
      || !animationState.direction
    ) {
      return
    }

    $animation.current.playSegments([animationSegments[animationState.index][0], animationSegments[animationState.index][1]], true)
  }, [$animation, animationState, animationSegments, setAnimationState])


  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [onWindowResize])

  useImperativeHandle(
    ref,
    () => ({
      onPreviousClick() {
        setAnimationState((prevState) => ({
          index: prevState.index - 1 >= 0 ? prevState.index - 1 : 0,
          direction: -1,
        }))
      },
      onNextClick() {
        setAnimationState((prevState) => ({
          index:
            prevState.index + 1 <= animationSegments.length - 1
              ? prevState.index + 1
              : animationSegments.length - 1,
          direction: 1,
        }))
      },
    }),
    [animationSegments]
  )

  return (
    <div className={cx(className, classes.container)}>
      <div
        className={classes.animationContainer}
        ref={$animationContainer}
      />
    </div>
  )
})

LottieTimeline.propTypes = {
  className: PropTypes.string,
  animationPath: PropTypes.string,
  animationSegments: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
}

LottieTimeline.defaultProps = {
  className: null,
  animationPath: null,
  animationSegments: [],
}

export default withMemo()(LottieTimeline)
