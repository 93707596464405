import React, { useEffect, useMemo } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'

import { withMemo, withWebsiteOpen, withGameClosed } from '../../decorators'
import { useIntl } from '../../intl/intl'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import LangSelector from '../../components/LangSelector'
import OnboardingTemplate from '../../templates/Onboarding'
import router from '../../router'
import routes from '../../router/routes'
import useLocalStorage from '../../hooks/useLocalStorage'
import useLangSelector from '../../hooks/useLangSelector'
import useConfiguration from '../../hooks/useConfiguration'
import * as appConfiguration from '../../configuration'


const Onboarding = (props) => {
  const t = useIntl()
  const { data, pageContext, location } = props
  const { setUserOnboarded } = useLocalStorage()
  const { configuration } = useConfiguration()
  const { langSelectorProps } = useLangSelector({ links: configuration?.locales ?? [], locale: pageContext?.language ?? '' })

  useEffect(() => {
    setUserOnboarded()
  }, [setUserOnboarded])

  const onboardingPageProps = useMemo(() => ({
    langSelectorProps,
    layoutProps: {
      seo: {
        lang: pageContext?.language ?? null,
        title: data.contentfulOnboarding?.seo?.title ?? '',
        description: data.contentfulOnboarding?.seo?.description?.description ?? '',
        image: appConfiguration.app.url + data.contentfulOnboarding?.seo?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
        url: location?.href ?? '',
        robots: 'noindex, follow',
      },
    },
    headerProps: {
      linkLogoProps: {
        link: t('balenciaga_logo_link'),
        target: '_blank',
      },
    },
    onBoardingProps: {
      textStep: 'step',
      textStepExtra: data.contentfulOnboarding.content.map((c) => c.subtitle),
      textPrev: t('previous'),
      textNext: t('next'),
      previousRoute: router(routes.vr.path),
      nextRoute: router(routes.code.path),
      text: data.contentfulOnboarding.content.map((c) => c.html.json),
      lottieTimelineProps: {
        animationPath: data.contentfulOnboarding.kind === 'HTC' ? '/animations/htc_seq.json' : '/animations/oculus_seq.json',
        animationSegments: data.contentfulOnboarding.content.map((c) => [c.start, c.end]),
      },
    },
  }), [langSelectorProps, pageContext.language, data.contentfulOnboarding.seo.title, data.contentfulOnboarding.seo.description.description, data.contentfulOnboarding.seo.picture.localFile.childImageSharp.fluid.src, data.contentfulOnboarding.content, data.contentfulOnboarding.kind, location.href, t])

  return (
    <Layout
      header={(
        <Header
          navigation={
            pageContext?.language !== 'zh' && <LangSelector {...onboardingPageProps.langSelectorProps} />
          }
          {...onboardingPageProps.headerProps}
        />
      )}
      {...onboardingPageProps.layoutProps}
    >
      <OnboardingTemplate {...onboardingPageProps.onBoardingProps} />
    </Layout>
  )
}

Onboarding.propTypes = {
  // eslint-disable-next-line
  location: PropTypes.object,
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
}

Onboarding.defaultProps = {
  location: null,
  data: null,
  pageContext: null,
}

export default withGameClosed(withWebsiteOpen(withMemo()(Onboarding)))

export const query = graphql`
   query onboarding($language: String){
    contentfulOnboarding(node_locale: { eq: $language }) {
      ...balenciagaF21Onboarding
    }
  }
`
