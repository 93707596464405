import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import { navigate } from 'gatsby-plugin-intl'
import { gsap, Power1 } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import { useIntl } from '../../intl/intl'
import withMemo from '../../decorators/withMemo'
import RichText from '../../components/RichText'
import LottieTimeline from '../../components/LottieTimeline'
import ProgressNav from '../../components/ProgressNav'

import useStyles from './styles'


const OnboardingTemplate = (props) => {
  const t = useIntl()
  const classes = useStyles(props)
  const { className, lottieTimelineProps, text, textStep, textStepExtra, textPrev, textNext, previousRoute, nextRoute } = props

  const $animation = useRef(null)
  const $textWrapper = useRef(null)
  const $stepsWrapper = useRef(null)

  const [currentStep, setCurrentStep] = useState(0)
  const [totalSteps] = useState(lottieTimelineProps?.animationSegments?.length)

  const handleFadeAnimation = (el, delay) => {
    gsap.fromTo(
      el,
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: Power1.easeIn, delay: delay || 0 }
    )
  }

  const handlePrev = () => {
    if (currentStep !== 0 && $animation.current) {
      setCurrentStep(currentStep - 1)
      $animation.current.onPreviousClick()
      if ($stepsWrapper.current) {
        handleFadeAnimation($stepsWrapper.current, 1)
      }
      if ($textWrapper.current) {
        handleFadeAnimation($textWrapper.current, 1.2)
      }
    } else if (previousRoute) {
      navigate(previousRoute)
    } else {
      return false
    }
  }

  const handleNext = () => {
    if (totalSteps && (currentStep !== totalSteps - 1) && $animation.current) {
      setCurrentStep(currentStep + 1)
      $animation.current.onNextClick()
      if ($stepsWrapper.current) {
        handleFadeAnimation($stepsWrapper.current, 1)
      }
      if ($textWrapper.current) {
        handleFadeAnimation($textWrapper.current, 1.2)
      }
    } else if (nextRoute) {
      navigate(nextRoute)
    } else {
      return false
    }
  }

  // animations
  // - contents
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current) {
      const animatedChildren = Array
        .from($animatedWrapper.current.children)
        .filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedChildren, timeline, 0.5, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [])
  // - texts
  useEffect(() => {
    const timeline = gsap.timeline()

    if ($textWrapper.current) {
      const animatedTextChildren = $textWrapper.current.querySelectorAll('li')

      staggerAnimation(0, animatedTextChildren, timeline, 1, '+=0.2', 0.3)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [currentStep])

  return (
    <main className={cx(className, classes.container)}>
      <div
        className={classes.wrapper}
        ref={$animatedWrapper}
      >
        <figure className={classes.figure}>
          <div
            className={classes.steps}
            ref={$stepsWrapper}
          >
            {`${t(textStep, { currentStep: currentStep + 1, totalSteps })}${textStepExtra[currentStep] ? ` ${textStepExtra[currentStep]}` : ''}`}
          </div>
          {lottieTimelineProps && (
            <div className={classes.animationWrapper}>
              <LottieTimeline
                className={classes.animation}
                ref={$animation}
                {...lottieTimelineProps}
              />
            </div>
          )}
          <figcaption
            ref={$textWrapper}
            className={classes.textWrapper}
          >
            {text && (
              <RichText
                className={classes.text}
                json={text[currentStep]}
              />
            )}
          </figcaption>
        </figure>
        <ProgressNav
          buttonNextProps={{ onClick: handleNext }}
          buttonPrevProps={{ onClick: handlePrev }}
          textPrev={textPrev}
          textNext={textNext}
          currentStep={currentStep}
          totalSteps={totalSteps}
        />
      </div>
    </main>
  )
}

export const OnboardingTemplatePropTypes = {
  className: PropTypes.string,
  lottieTimelineProps: PropTypes.shape(),
  text: PropTypes.arrayOf(PropTypes.shape(PropTypes.object)),
  textStep: PropTypes.string,
  textStepExtra: PropTypes.arrayOf(PropTypes.string),
  textPrev: PropTypes.string,
  textNext: PropTypes.string,
  previousRoute: PropTypes.string,
  nextRoute: PropTypes.string,
}

OnboardingTemplate.propTypes = OnboardingTemplatePropTypes

OnboardingTemplate.defaultProps = {
  className: null,
  lottieTimelineProps: null,
  text: null,
  textStep: '',
  textStepExtra: null,
  textPrev: '',
  textNext: '',
  previousRoute: '',
  nextRoute: '',
}

export default withMemo()(OnboardingTemplate)
