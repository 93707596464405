import { createUseStyles } from 'react-jss'

import { desktop, fonts } from '../../theme'


export default createUseStyles({
  container: {
    height: '100%',
    paddingTop: '4rem',
    paddingBottom: '1.6rem',
    ...desktop({
      paddingBottom: '3rem',
    }),
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    height: '100%',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    '& > *': {
      margin: 0,
    },
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    '& > * + *': {
      marginTop: '1.5rem',
    },
    ...desktop({
      paddingLeft: '2rem',
      paddingRight: '2rem',
      '& > * + *': {
        marginTop: '2.8rem',
      },
    }),
  },
  figure: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    '& > *': {
      margin: 0,
    },
    '& > $textWrapper': {
      marginTop: 'auto',
    },
    ...desktop({
      justifyContent: 'center',
      paddingLeft: 'calc(61% + 6.6rem)',
      paddingRight: '4rem',
      '& > $textWrapper': {
        marginTop: '2.5rem',
      },
    }),
  },
  steps: {
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    ...desktop({
      ...fonts.UniversBoldCondensed,
      fontSize: '2.1rem',
    }),
  },
  animationWrapper: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    transform: 'scale(1.04)', // to compensate for the very generous black space around graphics
    transformOrigin: 'center',
    overflow: 'hidden',
    ...desktop({
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      maxHeight: 'none',
      width: '61%',
      marginLeft: '0',
      marginRight: '0',
      transform: 'scale(1)',
    }),
  },
  animation: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  textWrapper: {
    '& .enter': {
      opacity: '0',
    },
    '& .enter-active': {
      opacity: '1',
      transition: 'opacity 200ms',
    },
    '& .exit': {
      opacity: '1',
    },
    '& .exit-active': {
      opacity: '0',
      transition: 'opacity 200ms',
    },
    ...desktop({
      minHeight: '20vh',
    }),
  },
  text: {
    fontSize: '1.2rem',
    lineHeight: '1.4',
    ...desktop({
      fontSize: '1.6rem',
    }),
    '& ol': {
      counterReset: 'item',
      listStyleType: 'none',
    },
    '& li': { display: 'block' },
    '& li:before': {
      content: 'counter(item) "  "',
      counterIncrement: 'item',
    },
  },
})
