import { createUseStyles } from 'react-jss'


export default createUseStyles({
  container: {
    display: 'flex',
    position: 'relative',
  },
  animationContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: '40rem',
  },
})
