import { createUseStyles } from 'react-jss'

import { colors, notDesktop } from '../../theme'


export default createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flex: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '2.6rem',
  },
  hideProgressOnMobile: {
    ...notDesktop({
      paddingBottom: 0,
    }),
    '& $progress': {
      ...notDesktop({
        display: 'none',
      }),
    },
  },
  progress: {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '1rem',
    overflow: 'hidden',
    border: `1px solid ${colors.pureWhite}`,
    background: 'transparent',
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      background: colors.pureWhite,
      transform: ({ percentage }) => (percentage && `translate3d(${0 - 100 + percentage}%, 0, 0)`),
      transition: 'transform 1000ms ease-in',
    },
  },
  // kept for semantic and sentimental reasons but can't be animated
  progressNative: {
    opacity: 0,
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '1rem',
    },
  },
  button: {
    minWidth: '15rem',
  },
})
