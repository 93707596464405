import { useEffect, useMemo, useCallback, useState } from 'react'


const WAITING_REASONS = {
  SUCCESS: 'success',
  EXIT: 'exit',
  QUEUE: 'queue',
}

const ERROR_REASONS = {
  BROWSER: 'browser',
  CONNECTION: 'connection',
  TECH: 'tech',
}

const LOCAL_STORAGE_KEYS = {
  USER_IS_ONBOARDED: 'balenciaga_fall21_is_onboarded',
  USER_IS_VR: 'balenciaga_fall21_vr_user',
  USER_COOLDOWN_DATE: 'balenciaga_fall21_cooldown_date',
  USER_COOLDOWN_REASON: 'balenciaga_fall21_cooldown_reason',
  USER_ERROR_REASON: 'balenciaga_fall21_error_reason',
}

const SHARE = {
  URL: 'balenciaga_fall21_share_url',
  IMAGE: 'balenciaga_fall21_share_image',
}

const useLocalStorage = () => {
  const [localStorage, setLocalStorage] = useState(null)

  const isUserOnboarded = useMemo(() => {
    if (!localStorage) {
      return
    }

    return !!+localStorage.getItem(LOCAL_STORAGE_KEYS.USER_IS_ONBOARDED)
  }, [localStorage])

  const isShareAvailable = useCallback(() => {
    if (!localStorage) {
      return
    }

    return !!localStorage.getItem(SHARE.URL)
  }, [localStorage])

  const setShareUrl = useCallback((url) => {
    if (!localStorage) {
      return
    }

    return localStorage.setItem(SHARE.URL, url)
  }, [localStorage])

  const setShareImage = useCallback((image) => {
    if (!localStorage) {
      return
    }

    return localStorage.setItem(SHARE.IMAGE, image)
  }, [localStorage])

  const shareUrl = useCallback(() => {
    if (!localStorage) {
      return
    }

    return localStorage.getItem(SHARE.URL)
  }, [localStorage])

  const shareImage = useCallback(() => {
    if (!localStorage) {
      return
    }

    return localStorage.getItem(SHARE.IMAGE)
  }, [localStorage])

  const userErrorReason = useMemo(() => {
    if (!localStorage) {
      return
    }

    return localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ERROR_REASON)
  }, [localStorage])

  const userCooldownReason = useMemo(() => {
    if (!localStorage) {
      return
    }

    return localStorage.getItem(LOCAL_STORAGE_KEYS.USER_COOLDOWN_REASON)
  }, [localStorage])

  const setUserOnboarded = useCallback(() => {
    if (!localStorage) {
      return
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_IS_ONBOARDED, '1')
  }, [localStorage])

  const setUserCooldownDate = useCallback((date) => {
    if (!localStorage) {
      return
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_COOLDOWN_DATE, date)
  }, [localStorage])

  const setUserCooldownReason = useCallback((reason) => {
    if (!localStorage) {
      return
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_COOLDOWN_REASON, reason)
  }, [localStorage])

  const setUserErrorReason = useCallback((reason) => {
    if (!localStorage) {
      return
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ERROR_REASON, reason)
  }, [localStorage])

  const userCooldownDate = useMemo(() => {
    if (!localStorage) {
      return
    }

    return localStorage.getItem(LOCAL_STORAGE_KEYS.USER_COOLDOWN_DATE)
  }, [localStorage])

  const resetShare = useCallback(() => {
    if (!localStorage) {
      return
    }

    localStorage.removeItem(SHARE.URL)
    localStorage.removeItem(SHARE.IMAGE)
  }, [localStorage])

  const resetCooldown = useCallback(() => {
    if (!localStorage) {
      return
    }

    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_COOLDOWN_DATE)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_COOLDOWN_REASON)
  }, [localStorage])

  const resetError = useCallback(() => {
    if (!localStorage) {
      return
    }

    return localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ERROR_REASON)
  }, [localStorage])

  useEffect(() => {
    setLocalStorage(window.localStorage)
  }, [localStorage])

  return {
    WAITING_REASONS,
    ERROR_REASONS,
    localStorage,
    isUserOnboarded,
    isShareAvailable,
    userCooldownDate,
    userErrorReason,
    userCooldownReason,
    shareUrl,
    shareImage,
    setShareUrl,
    setShareImage,
    setUserOnboarded,
    setUserCooldownDate,
    setUserCooldownReason,
    setUserErrorReason,
    resetCooldown,
    resetError,
    resetShare,
  }
}

export default useLocalStorage
