import { useMemo } from 'react'


const useLangSelector = ({ links, locale }) => {
  const langSelectorProps = useMemo(() => ({
    locales: links.map((link) => ({
      name: link?.title ?? '',
      abbreviation: link?.title.toUpperCase() ?? '',
      full: link?.title ?? '',
      current: link?.title === locale,
      linkProps: {
        link: link?.link ?? '',
        target: link?.target,
      },
    })),
  }), [links, locale])

  return { langSelectorProps }
}

export default useLangSelector
